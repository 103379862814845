@font-face {
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: normal;
    src: local('Airbnb Cereal App Book'), url('AirbnbCerealBook.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal App Light';
    font-style: normal;
    font-weight: normal;
    src: local('Airbnb Cereal App Light'), url('AirbnbCerealLight.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal App Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Airbnb Cereal App Medium'), url('AirbnbCerealMedium.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal App Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Airbnb Cereal App Bold'), url('AirbnbCerealBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal App Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Airbnb Cereal App Extra Bold'), url('AirbnbCerealExtraBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal App Black';
    font-style: normal;
    font-weight: normal;
    src: local('Airbnb Cereal App Black'), url('AirbnbCerealBlack.ttf') format('truetype');
}